





























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '../../../components/helper/form-base.vue';
import Users from '@/views/admin/user/user.vue';
import Roles from '@/views/admin/role/role.vue';
import Permissions from '@/views/admin/role/permissions.vue';

@Component({
    components: { FormBase, Users, Roles, Permissions }
})
export default class Security extends ListBase {
    tabModel: any = null;      

    mounted() {
    }

    created() {
    }
}
