














import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';

@Component({
    components: { VWidget, FormBase }
})
export default class Departments extends ListBase {
    model: any = {};
    formBase: boolean = false;

    get saving() {
        return this.$store.state.configuration.loading;
    }

    items = [
        this.gItem({ type: 'spacer', size: 12 }),
        this.gItem({ type: 'object', prop: 'DEFAULT_COMMERCIAL_ROL', label: 'Rol Para Ejecutivo Comercial', entity: 'role', loadOptions: false }),
        this.gItem({ type: 'object', prop: 'DEFAULT_COMMERCIAL_BOSS_ROL', label: 'Rol Para Jefe Comercial', entity: 'role', loadOptions: false }),
        this.gItem({ type: 'object', prop: 'DEFAULT_COBRANZA_ROL', label: 'Rol Para Analista de Cobranza', entity: 'role', loadOptions: false }),
        this.gItem({ type: 'object', prop: 'DEFAULT_RISK_ROL', label: 'Rol Para Analista de Riesgo', entity: 'role', loadOptions: false }),
        this.gItem({ type: 'object', prop: 'DEFAULT_RISK_MANAGER_ROL', label: 'Rol Para Jefe de Riesgo', entity: 'role', loadOptions: false }),
        this.gItem({ type: 'object', prop: 'DEFAULT_COMITE_ROL', label: 'Rol Para Miembro de Comite', entity: 'role', loadOptions: false }),
    ]

    buildModel(data) {
        let model = { };
        this.items.forEach(item => {
            if (item.prop != undefined && typeof data[item.prop] == "object") {
                var prop = item.prop;
                if (data[item.prop] != undefined) {
                    model[prop] = data[item.prop].id;
                }
            } else if (item.prop != undefined) {
                model[item.prop] = data[item.prop];
            }

        });
        return model;
    }

    async save() {           
        let model = this.buildModel(this.model);
        await this.$store.dispatch({
            type: 'configuration/save',
            data: model
        });
        Util.abp.notify.success(this, 'Las opciones de Roles fueron actualizadas!');
    }

    mounted() {
        this.model = Util.extend(true, {}, Util.abp.setting.values);
    }

    async created() {
        this.formBase = true;
        await this.$store.dispatch({
            type: 'role/getAllActive',
            data: { force: true}
        });
    }
}
