



















































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import Util from '@/lib/util';
import VWidget from '@/components/VWidget.vue';


@Component({
    components: { VWidget }
})
export default class Roles extends AbpBase {
    tabModel: any = null;
    expanded: Array<any> = [];
    loading: boolean = false;

    columns = [
        { value: 'name', text: '', sortable: false },
        { value: 'view', text: 'Consultar', width: '100', align: 'center', sortable: false },
        { value: 'store', text: 'Agregar', width: '100', align: 'center', sortable: false },
        { value: 'update', text: 'Modificar', width: '100', align: 'center', sortable: false },
        { value: 'destroy', text: 'Eliminar', width: '100', align: 'center', sortable: false },
        { value: 'export', text: 'Exportar', width: '100', align: 'center', sortable: false },
    ]

    get roles() {
        return this.$store.state.role.list;
    }

    get tabs() {
        let tabsList = [];
        this.roles.forEach(rol => {
            tabsList[rol.id] = [];

            this.$store.state.app.menuList.forEach(item => {
                if (item.children != null && item.children.length > 0) {
                    tabsList[rol.id].push({
                        name: item.meta.title, permission: item.permission, role: rol.id, level: 0, isMain: true,
                        view: this.hasPerm(rol.permissions, item.permission + '.view')
                    });

                    item.children.forEach(child => {
                        let tab = {
                            name: child.meta.title, permission: child.permission, role: rol.id, level: 1, isMain: false,
                            view: this.hasPerm(rol.permissions, child.permission + '.view'),
                            store: this.hasPerm(rol.permissions, child.permission + '.store'),
                            update: this.hasPerm(rol.permissions, child.permission + '.update'),
                            destroy: this.hasPerm(rol.permissions, child.permission + '.destroy'),
                            export: this.hasPerm(rol.permissions, child.permission + '.export')
                        };


                        tabsList[rol.id].push(tab);
                    });
                } else {
                    tabsList[rol.id].push({
                        name: item.meta.title, permission: item.permission, role: rol.id, level: 0, isMain: false,
                        view: this.hasPerm(rol.permissions, item.permission + '.view'),
                        store: this.hasPerm(rol.permissions, item.permission + '.store'),
                        update: this.hasPerm(rol.permissions, item.permission + '.update'),
                        destroy: this.hasPerm(rol.permissions, item.permission + '.destroy'),
                        export: this.hasPerm(rol.permissions, item.permission + '.export')
                    });
                }
            });

        });
        return tabsList;
    };

    get superAdmin() {
        return Util.abp.setting.get('SUPER_ADMIN');
    }
    
    hasPerm(permissions, perm) {
        let found = permissions.filter((child: any) => {
            return child.name === perm;
        });
        return found.length > 0;
    }

    async updatePermission(item, type) {
        this.loading = true;
        let data = { id: item.role, permission: item.permission + '.' + type };

        if (item[type] != undefined) {

            await this.$store.dispatch({
                type: 'role/' + (item[type] ? 'savePermission' : 'revokePermission'),
                data: data
            });
        }
        this.loading = false;
    }

    async mounted() {
        if (this.roles.length == 0) {
            await this.$store.dispatch({
                type: 'role/getAll',
                data: {
                    perPage: 50,
                    page: 1
                }
            });
        }
    }

    async created() {
        //this.getpage();
        //await this.$store.dispatch({
        //    type: 'role/getAllPermissions'
        //});
    }
}
